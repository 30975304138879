<template>
    <div id="collections-dashboard"> 
        <div class="vx-row">
            <div class="vx-col w-full mb-6">
                <vx-card class="big-title-card-bg-black h-auto">
                    <h1 class="rg-big-title extrabold rg-title">
                        Desempeño de la <span>cobranza</span>.
                    </h1>
                </vx-card>
            </div>
            <div class="vx-col w-full">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <vx-card title="Filtros.">
                            <div class="flex pl-2 mb-4">
                                <vs-select
                                    v-if="programIdSelected !== null"
                                    label="Zonas"
                                    v-model="zoneIdSelected"
                                    @change="addFilter('zone_id', $event)"
                                    class="mr-2">
                                    <vs-select-item text="Todas" value="0"></vs-select-item>
                                    <vs-select-item
                                        v-for="zone in programSelected.zones"
                                        :text="zone.name"
                                        :key="zone.id"
                                        :value="zone.id"></vs-select-item>
                                </vs-select>

                                <vs-select
                                    :disabled="zoneIdSelected === 0 && zoneSelected.regions.length < 0"
                                    label="Regiones"
                                    v-model="regionIdSelected"
                                    @change="addFilter('region_id', $event)">
                                    <vs-select-item text="Todas" value="0"></vs-select-item>
                                    <vs-select-item
                                        v-for="region in zoneSelected.regions"
                                        :text="region.name"
                                        :key="region.id"
                                        :value="region.id"></vs-select-item>
                                </vs-select>
                                <vs-button @click.stop="getGlobalPortfolio(0)" type="filled" class="mt-6 ml-4" icon="search"></vs-button>
                            </div>
                            <vs-table
                                ref="statusTable"
                                :data="data"
                                noDataText="No hay datos disponibles"
                                v-model="statusSelected"
                                @selected="showProjects"
                                class="text-right"
                            >
                                <template slot="thead">
                                    <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                </template>

                                <template slot-scope="{ data }">
                                    <vs-tr
                                        :key="indextr"
                                        v-for="(tr, indextr) in data"
                                        :data="tr"
                                    >
                                    <vs-td class="small bold">{{ tr.nombre }}</vs-td>
                                    <vs-td class="small">{{ tr.cantidad }}</vs-td>
                                    <vs-td class="small">{{ costFormatNoCents(tr.monto) }}</vs-td>
                                    <vs-td class="small">{{ costFormatNoCents(tr.insoluto) }}</vs-td>
                                    <vs-td class="small">{{ prcgFormat(tr.cartera * 100, 2) }}</vs-td>
                                    <vs-td class="small">{{ prcgFormat(tr.carteraAsc * 100, 2) }}</vs-td>
                                    <vs-td v-if="tr.creditStatus == vencido" class="small bold" style="color:red ">{{ prcgFormat(tr.carteraDesc * 100, 2) }}</vs-td>
                                    <vs-td v-else class="small">{{ prcgFormat(tr.carteraDesc * 100, 2) }}</vs-td>
                                    </vs-tr>

                                    <vs-tr state="dark">
                                    <vs-td class="small bold">Total</vs-td>
                                    <vs-td class="small bold">{{ total.cantidad }}</vs-td>
                                    <vs-td class="small bold">{{ costFormatNoCents(total.monto) }}</vs-td>
                                    <vs-td class="small bold">{{ costFormatNoCents(total.insoluto) }}</vs-td>
                                    <vs-td class="small bold">{{ prcgFormat(total.cartera * 100, 2) }}</vs-td>
                                    <vs-td />
                                    <vs-td />
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </vx-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import formatHelper from "@mixins/formatHelper";
import CreditStatusEnum from "@/modules/enums/CreditStatusEnum";

const columns = [
    { title: "", key: "blank", sortable: false }
    , { title: "PROYECTOS", key: "projects", sortable: false }
    , { title: "IMPORTE FINANCIADO", key: "financed_amount", sortable: false }
    , { title: "SALDO INSOLUTO", key: "outstanding_balance", sortable: false }
    , { title: "CARTERA", key: "wallet", sortable: false }
    , { title: "CARTERA DESCENDENTE", key: "wallet_desc", sortable: false }
    , { title: "CARTERA ASCENDENTE", key: "wallet_asc", sortable: false }
]

export default  {
    name: 'cartera-estatus',
    props: [],
    mixins: [formatHelper, CreditStatusEnum],
    async mounted() {
        await this.getGlobalPortfolio(0);
        const {data} = await axios.get(`/api/pgm/me/programs`)
        this.programs = data;
    },
    data() {
        return {
            vencido: CreditStatusEnum.VENCIDO,
            columns: columns,
            data: [],
            total: {
                cantidad: 0,
                monto: 0,
                saldo: 0,
                porVencer: 0,
                vencido: 0,
                insoluto: 0,
                amortizaciones: 0,
                intereses: 0,
                recargos: 0,
                iva: 0,
                cartera: 0,
            }
            , filters: {}
            , programs: []
            , zoneIdSelected: 0
            , regionIdSelected: 0
        }
    },
    methods: {
        async getGlobalPortfolio(){
            this.data = [];
            this.showLoading(true);
            await axios.get(`/api/kof/get/pgm-potential-applicants/collections?region=${this.regionIdSelected}&programId=${this.programIdSelected}`).then((response) => {
                this.data = response.data;
                this.showLoading(false);
            }).catch(error => {
                console.log(error);
                    this.showLoading(false);
                    // Vue.toasted.show('Ha ocurrido un error intente mas tarde.', { position: 'bottom-center', type:'error', closeOnSwipe: true, duration: 2000})
            });
        },
        async addFilter(filterName, ev) {
            if (ev.toString() === "0") {
                delete this.filters[filterName];
            } else {
                this.filters[filterName] = ev.toString();
            }

            if(filterName == "zone_id" && ev.toString() === "0"){
                this.zoneIdSelected = 0;
                this.regionIdSelected = 0;
            }
        },
    },
    computed: {
        programSelected() {
            let program = this.programs.filter(p => p.id === this.programIdSelected)
            return program[0] ?? null;
        },
        zoneSelected() {
            let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
            return zone[0] ?? {regions: []};
        },
        programIdSelected () {
            return this.currentProgramData.id;
        }
    },
    watch: {
        data:function () {
            const total = this.total;
            total.cantidad = 0;
            total.monto = 0;
            total.saldo = 0;
            total.porVencer = 0;
            total.vencido = 0;
            total.insoluto = 0;
            total.amortizaciones = 0;
            total.intereses = 0;
            total.recargos = 0;
            total.iva = 0;
            total.cartera = 0;
            this.data.forEach(function (item){
                total.cantidad += item.cantidad;
                total.monto += item.monto;
                total.saldo += item.saldo;
                total.porVencer += item.porVencer;
                total.vencido += item.vencido;
                total.insoluto += item.insoluto;
                total.amortizaciones += item.amortizaciones;
                total.intereses += item.intereses;
                total.recargos += item.recargos;
                total.iva += item.iva;
                total.cartera += item.cartera;
            });
        }
    }
}
</script>
<style scoped>
    #collections-dashboard td {
        border-left: 1px solid #ebecf0 !important;
    }
    #collections-dashboard th {
        border-left: 1px solid #ebecf0 !important;
    }
</style>